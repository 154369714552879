import React from 'react';
import LeagueBlock from 'components/block-elements/admin-leagues-block/admin-leagues-block';
import PageLayout from 'components/pageLayout';
import { Helmet } from 'react-helmet';

const AdminPanelBlockPage = (props) => {

    const searchParams = new URLSearchParams(props.location.search);
    const searchText = searchParams.get('searchText')
    const page = searchParams.get('page')

    return (
    <>
    <Helmet
    title='Admin Panel Leagues'>

    </Helmet>
        <PageLayout>
            <LeagueBlock searchParams={props.location.search} searchText={searchText} page={page}/>
        </PageLayout>
    </>
    );
};

export default AdminPanelBlockPage;
