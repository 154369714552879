import React, { useState } from "react";
import styles from './league-element.module.less';
import Modal from 'components/block-elements/modal/modal';
import LeaguesSettingsElement from 'components/block-elements/leagues-settings-element/leagues-settings-element';
const LeagueElement = (props) => {
    const [modalIsOpen, setIsOpen] = useState(false);

    const closeModal = (event) => {
        setIsOpen(false);
    }

    const openModal = (event) => {
        setIsOpen(true);
    }

    const updateData = () => {
        props.updateData();
    }

    return ( 
    <div className={styles.tournamentBox}>
        <div onClick={openModal} className={styles.mainContainer}>
            <div className={styles.flex}>
                <div>
                    <img className={styles.tournamentImage} src={props.tournamentCategoryLogo}></img>
                </div>

                <div className={styles.infoContainer}>
                    <div className={styles.infoBlock}>
                        <div className={styles.info}>
                            <div className={styles.infoDesctiptionText}>League Name: </div>
                            <div>{props.name}</div>
                        </div>
                    </div>

                    <div className={styles.infoBlock}>
                        <div className={styles.info}>
                            <div className={styles.infoDesctiptionText}>League Id: </div>
                            <div>{props.id}</div>
                        </div>
                    </div>

                    <div className={styles.infoBlock}>
                        <div className={styles.info}>
                            <div className={styles.infoDesctiptionText}>League Country Code : </div>
                            <div>{props.countryCode}</div>
                        </div>
                    </div>

                    <div className={styles.infoBlock}>
                        <div className={styles.info}>
                            <div className={styles.infoDesctiptionText}>League Display Name: </div>
                            <div>{props.displayTournamentCategoryName}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {modalIsOpen && <Modal setIsOpen={closeModal}>
            <LeaguesSettingsElement updateData={updateData} closeModal={closeModal}  {...props}/>
        </Modal>}
    </div>)
}

export default LeagueElement;